import React from 'react';
import {Helmet} from 'react-helmet';
import {StaticQuery, graphql} from 'gatsby';

interface Meta {
  name: string;
  content: string;
}

interface MetaProps {
  title: string;
  description: string;
  lang?: string;
}

interface SiteMetaDataProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
    };
  };
}

const DEFAULT_SEO_QUERY = graphql`
  query DEFAULT_SEO_QUERY {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

export default function SEO({
  title,
  description,
  lang = `en`,
}: MetaProps): JSX.Element {
  return (
    <StaticQuery
      query={DEFAULT_SEO_QUERY}
      render={(data: SiteMetaDataProps) => {
        return (
          <Helmet>
            <html lang={lang} />
            <title>{title || data.site.siteMetadata.title}</title>
            <meta
              name="description"
              content={description || data.site.siteMetadata.description}
            />
          </Helmet>
        );
      }}
    />
  );
}
